import React from "react";
import { useTranslation } from "react-i18next";
// import {
//   getUserRankSelector,
//   getNicknameSelector,
// } from "../../../store/selectors/authSelector";
import { checkBootstrapSizeBreak } from "../../../utils/device/checkDevice";
// import { Container, TitleContainer, LastProcessContainer } from "./home.style";
import DetailEval from "../evaluation/myEvaluationData";

const HomeView = (props) => {
  const { t } = useTranslation();
  const deviceSize = checkBootstrapSizeBreak();
  return <DetailEval user></DetailEval>;
};
export default HomeView;
