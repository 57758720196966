import React, { useState, useEffect } from "react";
import Detail from "./myEvaluationData.view";
import * as EvaluationServices from "../../../../services/EvaluationServices";
import { getIsEvaluatedSelector } from "../../../../store/selectors/authSelector";
import { useSelector } from "react-redux";
import { useEvaluations } from "../hook/useEvaluations";

const MyEvaluationContainer = ({ user }) => {
  const [data, setData] = useState(null);
  const isEvaluated = useSelector(getIsEvaluatedSelector);

  const loadData = () => {
    EvaluationServices.getMyEvaluation().then((result) => {
      setData(result);
    });
  };

  const { completeAutoEval } = useEvaluations(loadData);

  useEffect(() => {
    // if (isEvaluated) {
    loadData();
    // }
  }, [isEvaluated]);

  const handlerShowAutoEval = async () => {
    await completeAutoEval(data);
  };

  return (
    <>
      {data && data !== "NO" && (
        <Detail
          data={data}
          onAutoEval={handlerShowAutoEval}
          user={user || false}
        ></Detail>
      )}
    </>
  );
};

export default MyEvaluationContainer;
