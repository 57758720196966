import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../utils/breadCrumbs";
import PATHS from "../../../constants/paths";
import { ConfirmFile, ListError } from "./calibration.styled";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import showDialog from "../../utils/ShowDialog/ShowDialog";

const CalibrationForm = (props) => {
  const { t } = useTranslation();
  const { onSubmit, onSelectYear } = props;
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "application/vnd.ms-excel": [".xlx"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlxs",
        ],
      },
    });

  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.calibration.new") },
  ];

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let index = 0; index < 5; index++) {
    years.push({
      value: currentYear - index,
      label: currentYear - index,
    });
  }

  const send = () => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onSubmit(acceptedFiles[0]);
    } else {
      const title = t("forms.calibration.messages.register.title");
      const text = t("forms.calibration.messages.register.errorFile");
      const icon = "error";
      showDialog(title, text, icon, false, "OK", false);
    }
  };

  const renderFile = () => {
    const file = acceptedFiles[0];
    return (
      <ConfirmFile>
        {file.path} - {file.size} bytes
      </ConfirmFile>
    );
  };

  const renderErrorFile = () => {
    const { file, errors } = fileRejections[0];
    return (
      <div className="alert alert-danger mt-3" role="alert">
        <ListError>
          {errors.map((e) => (
            <li key={e.code}>
              {`${file.path} (${file.size} bytes)`} -{" "}
              {t(`file.error.${e.code}`)}
            </li>
          ))}
        </ListError>
      </div>
    );
  };

  return (
    <>
      <div className="row justify-content-center mt-4 mt-md-5">
        <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
          <h3 className="title">{t("forms.calibration.new")}</h3>
        </div>
      </div>
      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          <div className="row mb-3">
            <div className="col-12">
              <label className="control-label mandatory">
                {t("forms.calibration.labels.year")}
              </label>
              <Select
                id="type"
                name="type"
                onChange={(value) => {
                  onSelectYear(value);
                }}
                options={years}
                classNamePrefix="dark"
                placeholder=""
              />
            </div>
          </div>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>
              {acceptedFiles && acceptedFiles.length > 0
                ? renderFile()
                : t("forms.calibration.labels.file")}
            </p>
          </div>
          {fileRejections && fileRejections.length > 0 ? renderErrorFile() : ""}

          <div className="row justify-content-center">
            <div className="col-10 col-sm-11 text-center mt-5 mb-5">
              <button
                type="button"
                onClick={() => send()}
                className="btn btn-md btn-primary me-2"
              >
                {t("forms.calibration.buttons.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CalibrationForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default CalibrationForm;
