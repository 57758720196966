import { createAction } from "redux-actions";
import { apiGet, apiPost, apiPut, apiPostFile } from "../../api/index";
import {
  urlEvaluationSecured,
  urlToEvaluationSecured,
  urlEvaluationConfirmSecured,
  urlDataToEvaluationSecured,
  urlToEvaluationConsultSecured,
  urlGetMyEvaluationSecured,
  urlSetAutoEvaluationSecured,
  urlSetEvaluationSecured,
  urlNotifiedEvaluationSecured,
  urlCalibrationEvaluationSecured,
} from "../../api/urls";
import { PAGE_SIZE_10 } from "../../constants/index";

const generateFilterQuery = ({ filters }) => {
  let filter = "?";

  if (filters) {
    if (filters.year !== undefined) filter += "year=" + filters.year;
    if (filters.name !== undefined) filter += "&name=" + filters.name;
  }
  return filter;
};

const generateFilterAllQuery = ({
  filters,
  page,
  pageSize,
  sort,
  exportXls,
}) => {
  let filter = "?";

  page ? (filter += "page=" + page) : (filter += "page=1");
  pageSize
    ? (filter += "&pageSize=" + pageSize)
    : (filter += "&pageSize=" + PAGE_SIZE_10);

  if (filters) {
    if (filters.year !== undefined) filter += "&year=" + filters.year;
    if (filters.evaluated !== undefined)
      filter += "&evaluated=" + filters.evaluated;
    if (filters.evaluator !== undefined)
      filter += "&evaluator=" + filters.evaluator;
    if (filters.status !== undefined) filter += "&status=" + filters.status;
  }
  if (exportXls) {
    filter += "&exportXls=" + exportXls;
  }
  if (sort) filter += "&sort=" + sort;
  return filter;
};

/**
 * Filter list orders.
 */
export const getEval = createAction("GET_EVALUATION", (param) =>
  apiGet(urlToEvaluationSecured, param, true)()
);

/**
 * Generate Evaluation.
 */
export const generateEvaluation = createAction("GENERATE_EVALUATION", (obj) =>
  apiPost(urlEvaluationSecured, obj, undefined, true)()
);

/**
 * Get User Data.
 */
export const getSimpleEvaluation = createAction(
  "GET_USER_EVALUATION",
  (param) => apiGet(urlEvaluationSecured, param, undefined)()
);

/**
 * Update Evaluation.
 */
export const putEvaluation = createAction("UPDATE_EVALUATION", (obj, param) =>
  apiPut(urlEvaluationSecured, obj, param, true)()
);

/**
 * Confirm Evaluation.
 */
export const evaluationConfirm = createAction("CONFIRM_EVALUATION", (param) =>
  apiPost(urlEvaluationConfirmSecured, {}, param, true)()
);

/**
 * Get User Data.
 */
export const getDataToEvaluation = createAction(
  "GET_DATA_TO_EVALUATION",
  (param) => apiGet(urlDataToEvaluationSecured, param, undefined)()
);

/**
 * Filter consultEvaluations.
 */
export const consultEval = createAction("CONSULT_EVALUATION", (filter) =>
  apiGet(
    urlToEvaluationConsultSecured + generateFilterQuery(filter),
    undefined,
    true
  )()
);

/**
 * Filter list evaluations.
 */
export const filterEvaluations = createAction(
  "FILTER_EVALUATIONS",
  (filter, param) =>
    apiGet(urlEvaluationSecured + generateFilterAllQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const listEvaluationsToExport = createAction(
  "EXPORT_FILTER_EVALUATIONS",
  (filter, param) =>
    apiGet(urlEvaluationSecured + generateFilterAllQuery(filter), param, true)()
);

/**
 * Filter list orders.
 */
export const getMyEval = createAction("GET_MY_EVALUATION", () =>
  apiGet(urlGetMyEvaluationSecured, undefined, true)()
);

/**
 * Update Evaluation.
 */
export const putAutoEvaluation = createAction(
  "SET_AUTO_EVALUATION",
  (obj, param) => apiPut(urlSetAutoEvaluationSecured, obj, param, true)()
);

/**
 * Update Evaluation.
 */
export const putFinalEvaluation = createAction(
  "SET_FINAL_EVALUATION",
  (obj, param) => apiPut(urlSetEvaluationSecured, obj, param, true)()
);

/**
 * Update Evaluation.
 */
export const putNotifiedEvaluation = createAction(
  "NOTIFIED_EVALUATION",
  (obj, param) => apiPut(urlNotifiedEvaluationSecured, obj, param, true)()
);

/**
 * Create upload.
 */
export const createCalibration = createAction(
  "REGISTER_CALIBRATION",
  (formData) =>
    apiPostFile(urlCalibrationEvaluationSecured, formData, undefined, true)()
);
