import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withFormik, Field } from "formik";
import { useTranslation } from "react-i18next";
import {
  dataModel,
  validationDataModel,
  setInitialValues,
} from "./evaluationForm.data.form";
import InputText from "../../../utils/InputText";
import InputTextArea from "../../../utils/InputTextarea";
import Breadcrumb from "../../../utils/breadCrumbs";
import PATHS from "../../../../constants/paths";
import { POINTS, POINTS_ALL } from "../../../../constants/index";
import { useNavigate } from "react-router-dom";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import {
  TotalWeight,
  Subtitle,
  ContainerTooltip,
  AutoLabel,
  AutoIcon,
  AutoValue,
  ContainerTooltipSup,
  FinalPoints,
  FinalPointsValue,
} from "./evaluationForm.styled";
import { Tooltip } from "react-tooltip";
import { Can, AbilityContext } from "../../../security/permissions/Can";
import { USER_PERMISSIONS } from "../../../security/permissions/rolesPermission";

const NewEvaluationForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, cancelAction, dataForm, edit, evaluation, onlyView } =
    props;
  const navigate = useNavigate();
  const [current, setCurrent] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 });
  const [total, setTotal] = useState(0);
  const breads = [
    { label: t("home"), path: PATHS.HOME },
    { label: t("forms.evaluation.title"), path: PATHS.EVALUATION },
  ];

  if (onlyView) {
    breads.push({ label: t("forms.evaluation.detail") });
  } else {
    if (edit) {
      breads.push({ label: t("forms.evaluation.edit") });
    } else {
      breads.push({ label: t("forms.evaluation.new") });
    }
  }

  const comments = {};

  if (evaluation && evaluation.comments && evaluation.comments.length > 0) {
    for (const comment of evaluation.comments) {
      comments[`${comment.type}${comment.obj}`] = comment.comment;
    }
  }

  useEffect(() => {
    if (edit && evaluation) {
      const newCurrent = { ...current };
      const totalData =
        Number(evaluation.weightCriterial01) +
        Number(evaluation.weightCriterial02) +
        Number(evaluation.weightCriterial03) +
        Number(evaluation.weightCriterial04) +
        Number(evaluation.weightCriterial05);
      newCurrent[1] = Number(evaluation.weightCriterial01);
      newCurrent[2] = Number(evaluation.weightCriterial02);
      newCurrent[3] = Number(evaluation.weightCriterial03);
      newCurrent[4] = Number(evaluation.weightCriterial04);
      newCurrent[5] = Number(evaluation.weightCriterial05);
      setCurrent(newCurrent);
      setTotal(totalData);
    }
  }, [edit]);

  const onChangeWeight = (value, criteria) => {
    const newCurrent = { ...current };
    let result = total - newCurrent[criteria] + Number(value);
    newCurrent[criteria] = Number(value);
    setCurrent(newCurrent);
    setTotal(result);
  };

  const debounced = useDebouncedCallback((value, criteria) => {
    onChangeWeight(value, criteria);
  }, 500);

  const getPoints = (point) => {
    let label;
    if (point !== undefined) {
      const info = POINTS_ALL.find((p) => p.code === point);
      if (info) {
        label = t(info.label);
      }
    } else {
      label = t("point.na");
    }
    return label;
  };

  const renderSkills = () => {
    let listSkills = [];
    if (edit) {
      listSkills = evaluation.professionalSkill;
    } else {
      listSkills = dataForm.skills;
    }
    return listSkills.map((skill, index) => {
      return (
        <div key={`skill_${index}`}>
          <div className="row">
            <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
              {`${index + 1}.`}
            </div>
            <div className="col-1 mt-2">
              <input
                id={`weightSkill${index}`}
                className="form-control"
                name={`weightSkill${index}`}
                type="text"
                readOnly
                defaultValue={skill.weight}
              />
            </div>
            <div className="col-8 mt-2">
              <input
                data-tooltip-id={`tooltip_skill_${index}`}
                id={`skill${index}`}
                className="form-control"
                name={`skill${index}`}
                type="text"
                readOnly
                defaultValue={t(`skill:skill_${index + 1}.name`)}
              />
            </div>
            <Tooltip id={`tooltip_skill_${index}`}>
              <ContainerTooltip>
                {t(`skill:skill_${index + 1}.description`)}
              </ContainerTooltip>
            </Tooltip>
          </div>
          {skill?.autoPoints && (
            <div className="row align-items-center mt-2 mb-3">
              <div className="col-2"></div>
              <ContainerTooltipSup
                className="col-4"
                data-tooltip-id={`tooltip_skill_point_${index}`}
              >
                <Tooltip id={`tooltip_skill_point_${index}`}>
                  <ContainerTooltip>
                    {t(`forms.evaluation.labels.autoEval`, {
                      obj: skill?.name,
                    })}
                    {renderComment(`autoSkill${index + 1}`)}
                  </ContainerTooltip>
                </Tooltip>
                <AutoLabel>
                  <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                </AutoLabel>
                <AutoValue>
                  <strong>{`${getPoints(skill?.autoPoints)}`}</strong>
                </AutoValue>
              </ContainerTooltipSup>
              {skill.points && (
                <ContainerTooltipSup
                  className="col-4"
                  data-tooltip-id={`tooltip_manager05_${index}`}
                >
                  <Tooltip id={`tooltip_manager05_${index}`}>
                    <ContainerTooltip>
                      {t(`forms.evaluation.labels.evalManager`, {
                        obj: skill?.name,
                      })}
                      {renderComment(`finalSkill${index + 1}`, true)}
                    </ContainerTooltip>
                  </Tooltip>
                  <AutoLabel>
                    <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                  </AutoLabel>
                  <AutoValue>
                    <strong>{`${getPoints(skill?.points)}`}</strong>
                  </AutoValue>
                </ContainerTooltipSup>
              )}
            </div>
          )}
        </div>
      );
    });
  };

  const renderComment = (id, isManager = false) => {
    return (
      <>
        {comments[id] && (
          <>
            <div className="mt-2">
              <strong>
                {isManager
                  ? "Comentario del manager"
                  : "Comentario del trabajador"}
                :
              </strong>
            </div>
            <div>{comments[id]}</div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {onlyView && (
        <div className="row justify-content-center mt-4 mt-md-5">
          <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
            <h3 className="title">{`${t("forms.evaluation.detail")}: ${
              evaluation
                ? `${evaluation.dataUser.name} ${evaluation.dataUser.surname}`
                : ""
            }`}</h3>
          </div>
        </div>
      )}
      {!onlyView && (
        <div className="row justify-content-center mt-4 mt-md-5">
          <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
            {edit ? (
              <h3 className="title">{`${t("forms.evaluation.edit")}: ${
                evaluation
                  ? `${evaluation.dataUser.name} ${evaluation.dataUser.surname}`
                  : ""
              }`}</h3>
            ) : (
              <h3 className="title">{`${t("forms.evaluation.new")}: ${
                dataForm?.user
                  ? `${dataForm.user.name} ${dataForm.user.surname}`
                  : ""
              }`}</h3>
            )}
          </div>
        </div>
      )}

      <Breadcrumb items={breads} />
      <div className="row justify-content-center  ">
        <div className="col-12 col-md-11">
          {evaluation?.finalPoints && (
            <Can do={USER_PERMISSIONS.VIEW_FINAL_POINT}>
              <div className="row justify-content-center mt-md-4">
                <div className="col-12 col-md-11 marg-btm-1rem marg-top-40px">
                  <FinalPoints className="col-10 col-sm-11 d-flex align-items-center">
                    {t("forms.evaluation.labels.finalEvaluation")}
                    <FinalPointsValue>
                      {`${evaluation.finalPoints} (${t(
                        `rating.${evaluation.finalPoints}`
                      )})`}
                    </FinalPointsValue>
                  </FinalPoints>
                </div>
              </div>
            </Can>
          )}

          <form onSubmit={handleSubmit} autoComplete="off">
            <div className="row justify-content-center">
              <Subtitle className="col-10 col-sm-11 mt-4 mb-4">
                {t("forms.evaluation.labels.criterialObj")}
              </Subtitle>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 col-sm-11 text-center">
                <div className="row">
                  <div className="col-1 mt-2 d-flex align-items-center justify-content-end"></div>
                  <div className="col-1 mt-2 text-start">
                    {t("forms.evaluation.labels.weight")}
                  </div>
                  <div className="col-8 mt-2 text-start">
                    {t("forms.evaluation.labels.criterial")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-1 mt-2 d-flex align-items-start justify-content-end">
                    1.
                  </div>
                  <div className="col-1 mt-2">
                    <Field
                      id="weightCriterial01"
                      className="form-control"
                      name="weightCriterial01"
                      component={InputText}
                      onChangeValue={(e) => debounced(e, 1)}
                      fieldType="number"
                      placeholder={t("forms.evaluation.labels.weight")}
                      mandatory
                      readOnly={onlyView}
                    />
                  </div>
                  <div className="col-8 mt-2">
                    <Field
                      id="criterial01"
                      className="form-control"
                      name="criterial01"
                      component={InputTextArea}
                      placeholder={t("forms.evaluation.labels.criterial01")}
                      mandatory
                      rows={2}
                      readOnly={onlyView}
                    />
                  </div>
                </div>
                {evaluation?.pointAuto01 && (
                  <div className="row align-items-center mt-2 mb-3">
                    <div className="col-2"></div>
                    <ContainerTooltipSup
                      className="col-4"
                      data-tooltip-id={`tooltip_data01`}
                    >
                      <Tooltip id={`tooltip_data01`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.autoEval`, {
                            obj: t(`forms.evaluation.labels.criterial01`),
                          })}
                          {renderComment("auto1")}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        <strong>{`${getPoints(
                          evaluation?.pointAuto01
                        )}`}</strong>
                      </AutoValue>
                    </ContainerTooltipSup>
                    {evaluation.point01 && (
                      <ContainerTooltipSup
                        className="col-4"
                        data-tooltip-id={`tooltip_manager01`}
                      >
                        <Tooltip id={`tooltip_manager01`}>
                          <ContainerTooltip>
                            {t(`forms.evaluation.labels.evalManager`, {
                              obj: t(`forms.evaluation.labels.criterial01`),
                            })}
                            {renderComment("final1", true)}
                          </ContainerTooltip>
                        </Tooltip>
                        <AutoLabel>
                          <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                        </AutoLabel>
                        <AutoValue>
                          <strong>{`${getPoints(evaluation?.point01)}`}</strong>
                        </AutoValue>
                      </ContainerTooltipSup>
                    )}
                  </div>
                )}
                <div className="row">
                  <div className="col-1 mt-2 d-flex align-items-start justify-content-end">
                    2.
                  </div>
                  <div className="col-1 mt-2">
                    <Field
                      id="weightCriterial02"
                      className="form-control"
                      name="weightCriterial02"
                      component={InputText}
                      onChangeValue={(e) => debounced(e, 2)}
                      fieldType="number"
                      placeholder={t("forms.evaluation.labels.weight")}
                      mandatory
                      readOnly={onlyView}
                    />
                  </div>
                  <div className="col-8 mt-2">
                    <Field
                      id="criterial02"
                      className="form-control"
                      name="criterial02"
                      component={InputTextArea}
                      placeholder={t("forms.evaluation.labels.criterial02")}
                      rows={2}
                      mandatory
                      readOnly={onlyView}
                    />
                  </div>
                </div>
                {evaluation?.pointAuto02 && (
                  <div className="row align-items-center mt-2 mb-3">
                    <div className="col-2"></div>
                    <ContainerTooltipSup
                      className="col-4"
                      data-tooltip-id={`tooltip_data02`}
                    >
                      <Tooltip id={`tooltip_data02`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.autoEval`, {
                            obj: t(`forms.evaluation.labels.criterial02`),
                          })}
                          {renderComment("auto2")}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        <strong>{`${getPoints(
                          evaluation?.pointAuto02
                        )}`}</strong>
                      </AutoValue>
                    </ContainerTooltipSup>
                    {evaluation.point02 && (
                      <ContainerTooltipSup
                        className="col-4"
                        data-tooltip-id={`tooltip_manager02`}
                      >
                        <Tooltip id={`tooltip_manager02`}>
                          <ContainerTooltip>
                            {t(`forms.evaluation.labels.evalManager`, {
                              obj: t(`forms.evaluation.labels.criterial02`),
                            })}
                            {renderComment("final2", true)}
                          </ContainerTooltip>
                        </Tooltip>
                        <AutoLabel>
                          <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                        </AutoLabel>
                        <AutoValue>
                          <strong>{`${getPoints(evaluation?.point02)}`}</strong>
                        </AutoValue>
                      </ContainerTooltipSup>
                    )}
                  </div>
                )}
                <div className="row">
                  <div className="col-1 mt-2 d-flex align-items-start justify-content-end">
                    3.
                  </div>
                  <div className="col-1 mt-2">
                    <Field
                      id="weightCriterial03"
                      className="form-control"
                      name="weightCriterial03"
                      component={InputText}
                      onChangeValue={(e) => debounced(e, 3)}
                      fieldType="number"
                      placeholder={t("forms.evaluation.labels.weight")}
                      mandatory
                      readOnly={onlyView}
                    />
                  </div>
                  <div className="col-8 mt-2">
                    <Field
                      id="criterial03"
                      className="form-control"
                      name="criterial03"
                      component={InputTextArea}
                      placeholder={t("forms.evaluation.labels.criterial03")}
                      mandatory
                      rows={2}
                      readOnly={onlyView}
                    />
                  </div>
                </div>
                {evaluation?.pointAuto03 && (
                  <div className="row align-items-center mt-2 mb-3">
                    <div className="col-2"></div>
                    <ContainerTooltipSup
                      className="col-4"
                      data-tooltip-id={`tooltip_data03`}
                    >
                      <Tooltip id={`tooltip_data03`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.autoEval`, {
                            obj: t(`forms.evaluation.labels.criterial03`),
                          })}
                          {renderComment("auto3")}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        <strong>{`${getPoints(
                          evaluation?.pointAuto03
                        )}`}</strong>
                      </AutoValue>
                    </ContainerTooltipSup>
                    {evaluation.point03 && (
                      <ContainerTooltipSup
                        className="col-4"
                        data-tooltip-id={`tooltip_manager03`}
                      >
                        <Tooltip id={`tooltip_manager03`}>
                          <ContainerTooltip>
                            {t(`forms.evaluation.labels.evalManager`, {
                              obj: t(`forms.evaluation.labels.criterial03`),
                            })}
                            {renderComment("final3", true)}
                          </ContainerTooltip>
                        </Tooltip>
                        <AutoLabel>
                          <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                        </AutoLabel>
                        <AutoValue>
                          <strong>{`${getPoints(evaluation?.point03)}`}</strong>
                        </AutoValue>
                      </ContainerTooltipSup>
                    )}
                  </div>
                )}
                <div className="row">
                  <div className="col-1 mt-2 d-flex align-items-start justify-content-end">
                    4.
                  </div>
                  <div className="col-1 mt-2">
                    <Field
                      id="weightCriterial04"
                      className="form-control"
                      name="weightCriterial04"
                      component={InputText}
                      onChangeValue={(e) => debounced(e, 4)}
                      fieldType="number"
                      placeholder={t("forms.evaluation.labels.weight")}
                      mandatory
                      readOnly={onlyView}
                    />
                  </div>
                  <div className="col-8 mt-2">
                    <Field
                      id="criterial04"
                      className="form-control"
                      name="criterial04"
                      component={InputTextArea}
                      placeholder={t("forms.evaluation.labels.criterial04")}
                      mandatory
                      rows={2}
                      readOnly={onlyView}
                    />
                  </div>
                </div>
                {evaluation?.pointAuto04 && (
                  <div className="row align-items-center mt-2 mb-3">
                    <div className="col-2"></div>
                    <ContainerTooltipSup
                      className="col-4"
                      data-tooltip-id={`tooltip_data04`}
                    >
                      <Tooltip id={`tooltip_data04`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.autoEval`, {
                            obj: t(`forms.evaluation.labels.criterial04`),
                          })}
                          {renderComment("auto4")}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        <strong>{`${getPoints(
                          evaluation?.pointAuto04
                        )}`}</strong>
                      </AutoValue>
                    </ContainerTooltipSup>
                    {evaluation.point04 && (
                      <ContainerTooltipSup
                        className="col-4"
                        data-tooltip-id={`tooltip_manager04`}
                      >
                        <Tooltip id={`tooltip_manager04`}>
                          <ContainerTooltip>
                            {t(`forms.evaluation.labels.evalManager`, {
                              obj: t(`forms.evaluation.labels.criterial04`),
                            })}
                            {renderComment("final4", true)}
                          </ContainerTooltip>
                        </Tooltip>
                        <AutoLabel>
                          <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                        </AutoLabel>
                        <AutoValue>
                          <strong>{`${getPoints(evaluation?.point04)}`}</strong>
                        </AutoValue>
                      </ContainerTooltipSup>
                    )}
                  </div>
                )}
                <div className="row">
                  <div className="col-1 mt-2 d-flex align-items-start justify-content-end">
                    5.
                  </div>
                  <div className="col-1 mt-2">
                    <Field
                      id="weightCriterial05"
                      className="form-control"
                      name="weightCriterial05"
                      component={InputText}
                      onChangeValue={(e) => debounced(e, 5)}
                      fieldType="number"
                      placeholder={t("forms.evaluation.labels.weight")}
                      mandatory
                      readOnly={onlyView}
                    />
                  </div>
                  <div className="col-8 mt-2">
                    <Field
                      id="criterial05"
                      className="form-control"
                      name="criterial05"
                      component={InputTextArea}
                      placeholder={t("forms.evaluation.labels.criterial05")}
                      mandatory
                      rows={2}
                      readOnly={onlyView}
                    />
                  </div>
                </div>
                {evaluation?.pointAuto05 && (
                  <div className="row align-items-center mt-2 mb-3">
                    <div className="col-2"></div>
                    <ContainerTooltipSup
                      className="col-4"
                      data-tooltip-id={`tooltip_data05`}
                    >
                      <Tooltip id={`tooltip_data05`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.autoEval`, {
                            obj: t(`forms.evaluation.labels.criterial05`),
                          })}
                          {renderComment("auto5")}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                      </AutoLabel>
                      <AutoValue>
                        <strong>{`${getPoints(
                          evaluation?.pointAuto05
                        )}`}</strong>
                      </AutoValue>
                    </ContainerTooltipSup>
                    {evaluation.point05 && (
                      <ContainerTooltipSup
                        className="col-4"
                        data-tooltip-id={`tooltip_manager05`}
                      >
                        <Tooltip id={`tooltip_manager05`}>
                          <ContainerTooltip>
                            {t(`forms.evaluation.labels.evalManager`, {
                              obj: t(`forms.evaluation.labels.criterial05`),
                            })}
                            {renderComment("final5", true)}
                          </ContainerTooltip>
                        </Tooltip>
                        <AutoLabel>
                          <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                        </AutoLabel>
                        <AutoValue>
                          <strong>{`${getPoints(evaluation?.point05)}`}</strong>
                        </AutoValue>
                      </ContainerTooltipSup>
                    )}
                  </div>
                )}
                <div className="row">
                  <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                    {t("forms.evaluation.labels.totalWeight")}
                  </div>
                  <TotalWeight
                    $error={total > 100}
                    className="col-10 mt-2 text-start ps-4"
                  >{`${total}% ${
                    total > 100 ? t("forms.evaluation.maxWeight") : ""
                  }`}</TotalWeight>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <Subtitle className="col-10 col-sm-11 mt-5 mb-4">
                {t("forms.evaluation.labels.criterialStd")}
              </Subtitle>
            </div>
            {(dataForm?.skills || evaluation?.professionalSkill) && (
              <>
                <div className="row justify-content-center">
                  <div className="col-10 col-sm-11 text-center">
                    <div className="row">
                      <div className="col-1 mt-2 d-flex align-items-center justify-content-end"></div>
                      <div className="col-1 mt-2 text-start">
                        {t("forms.evaluation.labels.weight")}
                      </div>
                      <div className="col-8 mt-2 text-start">
                        {t("forms.evaluation.labels.skill")}
                      </div>
                    </div>
                    {renderSkills()}
                    <div className="row">
                      <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                        {t("forms.evaluation.labels.totalWeight")}
                      </div>
                      <TotalWeight className="col-10 mt-2 text-start ps-4">{`100% `}</TotalWeight>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="row mt-5 mb-5">
              <div className="col-12 text-center">
                <button
                  type="button"
                  onClick={() => cancelAction()}
                  className="btn btn-lg btn-secondary me-2"
                >
                  {t("forms.evaluation.buttons.cancel")}
                </button>
                {!onlyView && (
                  <button type="submit" className="btn btn-lg btn-primary">
                    {t("forms.evaluation.buttons.save")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NewEvaluationForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

const NewEvaluationFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: "NewEvaluationForm",
})(NewEvaluationForm);

export default NewEvaluationFormF;
