import React, { useState } from "react";
import CalibrationForm from "./calibration.view";
import * as EvaluationServices from "../../../services/EvaluationServices";
import showDialog from "../../utils/ShowDialog/ShowDialog";
import { useTranslation } from "react-i18next";

const CalibrationFormContainer = ({ uuid }) => {
  const { t } = useTranslation();
  const [yearSelected, setYearSelected] = useState(null);

  const handleSubmit = async (file) => {
    let title = "";
    let text = "";
    let icon = "";

    if (!yearSelected) {
      title = t("forms.calibration.messages.register.title");
      text = t("forms.calibration.messages.register.errorYear");
      icon = "error";
      showDialog(title, text, icon, false, "OK", false);
      return false;
    }
    try {
      let result;
      if (file && yearSelected) {
        result = await EvaluationServices.calibration(file, yearSelected);
      }
      if (result) {
        title = t("forms.calibration.messages.register.title");
        text = t("forms.calibration.messages.register.success");
        icon = "success";
        showDialog(title, text, icon, false, "OK", false);
      } else {
        title = t("forms.calibration.messages.register.title");
        text = t("forms.calibration.messages.register.error");
        icon = "error";
      }
    } catch (error) {
      // console.log("Error", error);
    }
  };

  const handleOnSelectYear = async (value) => {
    setYearSelected(value);
  };

  return (
    <CalibrationForm
      onSubmit={handleSubmit}
      onSelectYear={handleOnSelectYear}
    />
  );
};

CalibrationFormContainer.propTypes = {};

export default CalibrationFormContainer;
