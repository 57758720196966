import React from "react";
import { useTranslation } from "react-i18next";
import { EVAL_STATUS, POINTS_ALL } from "../../../../constants/index";
import {
  LabelText,
  ValueText,
  Subtitle,
  ContainerTooltip,
  AutoLabel,
  AutoIcon,
  AutoValue,
} from "./myEvaluationData.styled";
import { Tooltip } from "react-tooltip";

const MyEvaluationView = (props) => {
  const { t } = useTranslation();
  const { data, onAutoEval, user } = props;
  let status;
  // const evaluation = data && data.evaluation ? data.evaluation[0] : data;
  if (data) {
    status = EVAL_STATUS.find((f) => f.code === data.status);
  }

  const comments = {};

  if (data && data.comments && data.comments.length > 0) {
    for (const comment of data.comments) {
      comments[`${comment.type}${comment.obj}`] = comment.comment;
    }
  }

  const getPoints = (point) => {
    let label;
    if (point !== undefined) {
      const info = POINTS_ALL.find((p) => p.code === point);
      if (info) {
        label = t(info.label);
      }
    } else {
      label = t("point.na");
    }
    return label;
  };

  const renderComment = (id, isManager = false) => {
    return (
      <>
        {comments[id] && (
          <>
            <div className="mt-2">
              <strong>
                {isManager
                  ? "Comentario del manager"
                  : "Comentario del trabajador"}
                :
              </strong>
            </div>
            <div>{comments[id]}</div>
          </>
        )}
      </>
    );
  };

  const renderSkills = () => {
    let listSkills = data.professionalSkill;
    return listSkills.map((skill, index) => {
      return (
        <div key={`skill_${index}`}>
          <div className="row">
            <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
              {`${index + 1}.`}
            </div>
            <div className="col-1 mt-2 text-center">
              <strong>{`${skill.weight}%`}</strong>
            </div>
            <div className="col-8 mt-2">
              <strong data-tooltip-id={`tooltip_skill_${index}`}>
                {skill.name}
              </strong>
            </div>
          </div>
          <Tooltip id={`tooltip_skill_${index}`}>
            <ContainerTooltip>
              {t(`skill:skill_${index + 1}.description`)}
            </ContainerTooltip>
          </Tooltip>
          {skill?.autoPoints && (
            <div className="row align-items-center mt-2 mb-3">
              <div className="col-2"></div>
              <div className="col-5">
                <Tooltip id={`tooltip_skill_point_${index}`}>
                  <ContainerTooltip>
                    {t(`forms.evaluation.labels.autoEval`, {
                      obj: skill?.name,
                    })}
                    {renderComment(`autoSkill${index + 1}`)}
                  </ContainerTooltip>
                </Tooltip>
                <AutoLabel>
                  <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                </AutoLabel>
                <AutoValue data-tooltip-id={`tooltip_skill_point_${index}`}>
                  <strong>{`${getPoints(skill?.autoPoints)}`}</strong>
                </AutoValue>
              </div>
              {skill.points && !user && (
                <div className="col-5">
                  <Tooltip id={`tooltip_manager05_${index}`}>
                    <ContainerTooltip>
                      {t(`forms.evaluation.labels.evalManager`, {
                        obj: skill?.name,
                      })}
                      {renderComment(`finalSkill${index + 1}`, true)}
                    </ContainerTooltip>
                  </Tooltip>
                  <AutoLabel>
                    <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                  </AutoLabel>
                  <AutoValue data-tooltip-id={`tooltip_manager05_${index}`}>
                    <strong>{`${getPoints(skill?.points)}`}</strong>
                  </AutoValue>
                </div>
              )}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-md-11">
          <h3 className="title">{`${t("forms.evaluation.detail")} ${
            data?.year
          }`}</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-11">
          <div className="row justify-content-center">
            <div className="col-12 mt-3 d-flex">
              <LabelText>{t("forms.evaluation.labels.evaluated")}:</LabelText>
              <ValueText>{`${data?.dataUser?.name} ${data?.dataUser?.surname}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.year")}:</LabelText>
              <ValueText>{`${data?.year}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.status")}:</LabelText>
              <ValueText>{`${t(status?.label)}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex">
              <LabelText>{t("forms.evaluation.labels.evaluator")}:</LabelText>
              <ValueText>{`${data?.evaluator?.name} ${data?.evaluator?.surname}`}</ValueText>
            </div>
          </div>
          <div className="row justify-content-center">
            <Subtitle className="col-12 mt-4 mb-2">
              {t("forms.evaluation.labels.criterialObj")}
            </Subtitle>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end"></div>
                <div className="col-1 mt-2">
                  {t("forms.evaluation.labels.weight")}
                </div>
                <div className="col-8 mt-2 text-start">
                  {t("forms.evaluation.labels.criterial")}
                </div>
              </div>
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  1.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${data?.weightCriterial01}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${data?.criterial01}`}</strong>
                </div>
              </div>
              {data?.pointAuto01 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div className="col-5 text-start">
                    <Tooltip id={`tooltip_data01`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial01`),
                        })}
                        {renderComment("auto1")}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue data-tooltip-id={`tooltip_data01`}>{`${getPoints(
                      data?.pointAuto01
                    )}`}</AutoValue>
                  </div>
                  {data.point01 && !user && (
                    <div className="col-5 text-start">
                      <Tooltip id={`tooltip_manager01`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial01`),
                          })}
                          {renderComment("final1", true)}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue
                        data-tooltip-id={`tooltip_manager01`}
                      >{`${getPoints(data?.point01)}`}</AutoValue>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  2.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${data?.weightCriterial02}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${data?.criterial02}`}</strong>
                </div>
              </div>
              {data?.pointAuto02 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div className="col-5 text-start">
                    <Tooltip id={`tooltip_data02`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial02`),
                        })}
                        {renderComment("auto2")}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue data-tooltip-id={`tooltip_data02`}>{`${getPoints(
                      data?.pointAuto02
                    )}`}</AutoValue>
                  </div>
                  {data.point02 && !user && (
                    <div className="col-5 text-start">
                      <Tooltip id={`tooltip_manager02`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial02`),
                          })}
                          {renderComment("final2", true)}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue
                        data-tooltip-id={`tooltip_manager02`}
                      >{`${getPoints(data?.point02)}`}</AutoValue>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  3.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${data?.weightCriterial03}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${data?.criterial03}`}</strong>
                </div>
              </div>
              {data.pointAuto03 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div className="col-5 text-start">
                    <Tooltip id={`tooltip_data03`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial03`),
                        })}
                        {renderComment("auto3")}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue data-tooltip-id={`tooltip_data03`}>{`${getPoints(
                      data.pointAuto03
                    )}`}</AutoValue>
                  </div>
                  {data.point03 && !user && (
                    <div className="col-5 text-start">
                      <Tooltip id={`tooltip_manager03`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial03`),
                          })}
                          {renderComment("final3", true)}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue
                        data-tooltip-id={`tooltip_manager03`}
                      >{`${getPoints(data.point03)}`}</AutoValue>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  4.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${data?.weightCriterial04}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${data?.criterial04}`}</strong>
                </div>
              </div>
              {data.pointAuto04 && (
                <div className="row align-items-center mt-3 mb-4">
                  <div className="col-2"></div>
                  <div className="col-5 text-start">
                    <Tooltip id={`tooltip_data04`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.autoEval`, {
                          obj: t(`forms.evaluation.labels.criterial04`),
                        })}
                        {renderComment("auto4")}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                    </AutoLabel>
                    <AutoValue data-tooltip-id={`tooltip_data04`}>{`${getPoints(
                      data.pointAuto04
                    )}`}</AutoValue>
                  </div>
                  {data.point04 && !user && (
                    <div className="col-5 text-start">
                      <Tooltip id={`tooltip_manager04`}>
                        <ContainerTooltip>
                          {t(`forms.evaluation.labels.evalManager`, {
                            obj: t(`forms.evaluation.labels.criterial04`),
                          })}
                          {renderComment("final4", true)}
                        </ContainerTooltip>
                      </Tooltip>
                      <AutoLabel>
                        <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                      </AutoLabel>
                      <AutoValue
                        data-tooltip-id={`tooltip_manager04`}
                      >{`${getPoints(data.point04)}`}</AutoValue>
                    </div>
                  )}
                </div>
              )}
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end">
                  5.
                </div>
                <div className="col-1 mt-2">
                  <strong>{`${data?.weightCriterial05}%`}</strong>
                </div>
                <div className="col-8 mt-2 text-start">
                  <strong>{`${data?.criterial05}`}</strong>
                </div>
              </div>
            </div>
            {data.pointAuto05 && (
              <div className="row align-items-center mt-3 mb-4">
                <div className="col-2"></div>
                <div className="col-5 text-start">
                  <Tooltip id={`tooltip_data05`}>
                    <ContainerTooltip>
                      {t(`forms.evaluation.labels.autoEval`, {
                        obj: t(`forms.evaluation.labels.criterial05`),
                      })}
                      {renderComment("auto5")}
                    </ContainerTooltip>
                  </Tooltip>
                  <AutoLabel>
                    <AutoIcon className="fa-solid fa-user-pen"></AutoIcon>
                  </AutoLabel>
                  <AutoValue data-tooltip-id={`tooltip_data05`}>{`${getPoints(
                    data.pointAuto05
                  )}`}</AutoValue>
                </div>
                {data.point05 && !user && (
                  <div className="col-5  text-start">
                    <Tooltip id={`tooltip_manager05`}>
                      <ContainerTooltip>
                        {t(`forms.evaluation.labels.evalManager`, {
                          obj: t(`forms.evaluation.labels.criterial05`),
                        })}
                        {renderComment("final5", true)}
                      </ContainerTooltip>
                    </Tooltip>
                    <AutoLabel>
                      <AutoIcon className="fa-solid fa-user-tie"></AutoIcon>
                    </AutoLabel>
                    <AutoValue
                      data-tooltip-id={`tooltip_manager05`}
                    >{`${getPoints(data.point05)}`}</AutoValue>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="row justify-content-center">
            <Subtitle className="col-12 mt-3 mb-2">
              {t("forms.evaluation.labels.criterialStd")}
            </Subtitle>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-1 mt-2 d-flex align-items-center justify-content-end"></div>
                <div className="col-1 mt-2">
                  {t("forms.evaluation.labels.weight")}
                </div>
                <div className="col-8 mt-2 text-start">
                  {t("forms.evaluation.labels.skill")}
                </div>
              </div>
            </div>
          </div>
          {data && renderSkills()}

          {data?.autoEvalAllowed && (
            <div className="row justify-content-center mt-5 mb-5">
              <div className="col-12 text-center">
                <button
                  className="btn btn-primary btn-md"
                  onClick={() => onAutoEval()}
                >
                  {t("forms.evaluation.buttons.autoEval")}
                </button>
              </div>
            </div>
          )}
          <div className="mb-5"></div>
        </div>
      </div>
    </>
  );
};

MyEvaluationView.propTypes = {};

export default MyEvaluationView;
