import { Navigate, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import AuthConfirmPage from "./pages/authConfirm";
import LoginPage from "./pages/login";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PATHS from "./constants/paths";
import EvaluationListPage from "./pages/evaluation/list";
import EvaluationFormPage from "./pages/evaluation/form";
import EvaluationConsultPage from "./pages/evaluation/consult";
import EvaluationDetailPage from "./pages/evaluation/detail";
import EvaluationListAllPage from "./pages/evaluation/listAll";
import ConfigFormPage from "./pages/config";
import CalibrationFormPage from "./pages/calibration";

function App() {
  // const navigate = useNavigate();
  // const { user } = AuthContainer.useContainer();

  return (
    <Routes>
      {/* <Route path="/logout" element={<Logout />} /> */}
      <Route path="/user" element={<ProtectedRoutes />}>
        <Route path={PATHS.HOME} exact element={<HomePage />} />
        <Route path={PATHS.CONFIG} exact element={<ConfigFormPage />} />
        <Route
          path={PATHS.CALIBRATION}
          exact
          element={<CalibrationFormPage />}
        />
        <Route
          path={PATHS.RH_LIST_EVALUATION}
          exact
          element={<EvaluationListAllPage />}
        />
        <Route
          path={PATHS.LIST_EVALUATION}
          exact
          element={<EvaluationConsultPage />}
        />
        <Route
          path={PATHS.NEW_EVALUATION}
          exact
          element={<EvaluationFormPage />}
        />
        <Route
          path={PATHS.EDIT_EVALUATION}
          exact
          element={<EvaluationFormPage />}
        />
        <Route
          path={PATHS.VIEW_EVALUATION}
          exact
          element={<EvaluationDetailPage />}
        />
        <Route path={PATHS.EVALUATION} exact element={<EvaluationListPage />} />
      </Route>

      <Route
        path={PATHS.EXTERNAL_CONFIRM_LOGIN}
        exact
        element={<AuthConfirmPage />}
      />
      <Route path="/login" exact element={<LoginPage />} />
      <Route path="/" element={<Navigate to={"/login"} />} />
    </Routes>
  );
}

export default App;
