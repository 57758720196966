import React from "react";
import CalibrationForm from "../../components/user/calibration";
import Layout from "../../components/layouts/verticalLayout";

const CalibrationPage = () => {
  return (
    <Layout
      body={
        <>
          <CalibrationForm />
        </>
      }
    />
  );
};

CalibrationPage.propTypes = {};

export default CalibrationPage;
